import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { apiPath } from '../../util/api';

const useStyles = makeStyles({
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    fileName: {
        margin: 'auto 0 auto 5px',
    },
    flexContainer: {
        height: '100%',
    },
    paperContainer: {
        width: '95%',
        height: '95%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    iFrame: {
        backgroundColor: 'red',
        flex: 1,
        border: 'none',
    },
  });

function refreshIFrame() {
    document.getElementById('preview-frame').src += '';
}

export default function Preview({projectId, setPreviewHidden}) {
    const classes = useStyles();

    return (
        <div className={classes.flexContainer}>
            <Paper className={classes.paperContainer} elevation={3}>
                <div className={classes.header}>
                    <span className={classes.fileName}>{projectId}</span>
                    <div>
                        <Button onClick={refreshIFrame}>↻</Button>
                        <Button onClick={() => {setPreviewHidden(true)}}>X</Button>
                    </div>
                </div>
                <iframe id="preview-frame" className={classes.iFrame} src={apiPath + `projects/${projectId}/getStaged/`} />
            </Paper>
        </div>
    );
}
