import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
    FileNameContainer: {
      display: 'flex',
    },
    FileNameButton: {
        flex: 1,
    },
    htmlFile: {
        backgroundColor: 'rgb(144, 183, 233)',
    },
    jsFile: {
        backgroundColor: 'rgb(243, 209, 124)',
    },
    cssFile: {
        backgroundColor: 'rgb(175, 153, 235)',
    },
  });

export default function FileTree({files, setSelectedFile}) {
    const classes = useStyles();

    return (
        <div>
            {Object.entries(files).map(file =>{
                file = file[1]
                let modified = '';
                console.log(file);
                if (file.originalContent && file.content !== file.originalContent) {
                    modified = '*';
                    console.log(modified)
                }

                let fileClass = "";

                if (file.fileType === "html") {
                    fileClass = classes.htmlFile;
                } else if (file.fileType === "js") {
                    fileClass = classes.jsFile;
                } else if (file.fileType === "css") {
                    fileClass = classes.cssFile;
                }


                return (
                    <div key={file.name} className={classes.FileNameContainer}>
                        <button className={classNames(classes.FileNameButton, fileClass)} onClick={() => setSelectedFile(file.name)}>{file.fileName}.{file.fileType}{modified}</button>
                    </div>
                )
            })}
        </div>
    );
}
