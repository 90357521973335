import React from 'react';
import {Controlled} from 'react-codemirror2'
import { makeStyles } from '@material-ui/core/styles';

require('./CodeMirror.css');
require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');

require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
require('codemirror/mode/htmlmixed/htmlmixed');
require('codemirror/mode/css/css');

const useStyles = makeStyles({
    CodeMirrorElement: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
  });

export default function FileTree({file, setFileContent}) {
    const classes = useStyles();
    file = {content: '', name: '',...file}
    console.log(file)
    
    let mode = '';
    switch (file.fileType) {
        case 'html':
            mode = 'htmlmixed';
            break;
        case 'js':
            mode = 'javascript';
            break;
    }

    return (
            <Controlled
                className={classes.CodeMirrorElement}
                value={file.content}
                options={{
                    mode: mode,
                    lineNumbers: true,
                    lineWrapping: true,
                }}
                onBeforeChange={(editor, data, value) => {
                    setFileContent(file.name, value);
                  }}
            />
    );
}
