import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import Link from '@material-ui/core/Link';

import FileTree from '../fileTree/FileTree';
import CodeMirror from '../codeMirror/CodeMirror';
import Preview from '../preview/Preview';
import { apiPath, saveFile, stageProject, publishProject } from '../../util/api';


const useStyles = makeStyles((theme) =>
createStyles({
    EditorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        position: 'relative',
    },
    Editor: {
        flex: 1,
        position: 'relative',
    },
    EditorFlexContainer: {

    },
    EditorContainer: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      height: '100%',
    },
    EditorNavBar: {
    },
    FileTree: {
        // flex: 1,
        maxWidth: '200px',
        minWidth: '200px',
    },
    CodeMirror: {
        display: 'flex',
        backgroundColor: 'red',
        flex: 1,
    },
    Preview: {
        flex: 1,
    },
  }));

function convertFilesListToMap(filesList) {
    let filesMap = {};
    for (let file of filesList) {
        filesMap[file.name] = file;
    }
    return filesMap;
};

export default function Editor({projectid: projectId}) {
    const classes = useStyles();
    console.log('Project id from inside editor: ' + projectId)

    const [fileAnchorEl, setFileAnchorEl] = React.useState(null);
    const [projectAnchorEl, setProjectAnchorEl] = React.useState(null);
    const [data, setData] = React.useState({
        files: {},
        selectedFile: '',
    });
    const [previewHidden, setPreviewHidden] = React.useState(true);

    const handleFileMenuClick = (event) => {
      setFileAnchorEl(event.currentTarget);
    };
  
    const handleFileMenuClose = () => {
      setFileAnchorEl(null);
    };

    const handleProjectMenuClick = (event) => {
        setProjectAnchorEl(event.currentTarget);
    };

    const handleProjectMenuClose = () => {
        setProjectAnchorEl(null);
    };

    const saveFileAndHandleClose = () => {
        // save that shit with open editor content
        (async () => {
            let response = await saveFile(projectId, data.files[data.selectedFile]);
            // Update entry in data
            resetFile(response);
        })()
        // saveFile(projectId, data.files[data.selectedFile]);
        setFileAnchorEl(null);
    };

    const openUrl = (url) => {
        window.open(url, "_blank");
    };

    // const stageProjectAndHandleClose

    // Set selected file, used by the file tree.
    const setSelectedFile = (fileId) => {
        setData({...data, selectedFile: fileId})
        console.log(data)
    };

    const setFileContent = (fileId, content) => {
        // stash the old content for comparison
        let file = data.files[fileId];
        if (!file) return
        if (!file.originalContent) {
            file.originalContent = file.content;
        }
        file.content = content;
        let files = {...data.files}
        files[fileId] = file
        console.log(files)
        // Need to setData to make a refresh
        setData({...data, files});
    };

    const resetFile = (file) => {
        if (!file) return
        let fileId = file.name;
        let files = {...data.files}
        files[fileId] = file
        console.log(files)
        // Need to setData to make a refresh
        setData({...data, files});
    }


    // Pull the file list.
    React.useEffect(() => {
        (async () => {
            if (projectId === 'None') return;
            const response = await (
                await fetch(apiPath + 'projects/' + projectId + '/files/')
            ).json()
            let filesMap = convertFilesListToMap(response);
            let newFiles = {
                files: filesMap,
            };
            setData({...data, ...newFiles});
        })()
      }, [apiPath, projectId])

    
    // If we don't have a project id, then go back to the project selector.
    if (projectId === 'None') {
        return <Redirect to="/" />
    }
    return (
        <div className={classes.EditorWrapper}>
            <div className={classes.EditorNavBar}>
                <Button aria-controls="file-menu" aria-haspopup="true" color="inherit" onClick={handleFileMenuClick}>
                    <Typography>
                        File
                    </Typography>
                </Button>
                <Button aria-controls="project-menu" aria-haspopup="true" color="inherit" onClick={handleProjectMenuClick}>
                    <Typography>
                        Project
                    </Typography>
                </Button>
                <Menu
                    id="file-menu"
                    anchorEl={fileAnchorEl}
                    keepMounted
                    open={Boolean(fileAnchorEl)}
                    onClose={handleFileMenuClose}
                >
                    <MenuItem onClick={handleFileMenuClose}>New File</MenuItem>
                    <MenuItem onClick={saveFileAndHandleClose}>Save File</MenuItem>
                    <MenuItem disabled>Bundle File</MenuItem>
                    {/* Set this file as home, if HTML type */}
                    <MenuItem disabled>Set Home</MenuItem>
                </Menu>
                <Menu
                    id="project-menu"
                    anchorEl={projectAnchorEl}
                    keepMounted
                    open={Boolean(projectAnchorEl)}
                    onClose={handleProjectMenuClose}
                >
                    <MenuItem onClick={() => {stageProject(projectId);setProjectAnchorEl(null);}}>Stage Project</MenuItem>
                    <MenuItem onClick={() => {setPreviewHidden(false);setProjectAnchorEl(null);}}>Open Staged Preview</MenuItem>
                    <MenuItem onClick={() => {publishProject(projectId);setProjectAnchorEl(null);}}>Publish Project</MenuItem>
                    <MenuItem onClick={() => {openUrl("http://editor.exterkamp.codes/published/" + projectId + "/");setProjectAnchorEl(null);}}>
                        Open Published Project
                    </MenuItem>
                </Menu>
            </div>
            {/* <div className={classes.EditorFlexContainer}> */}
            <div className={classes.EditorContainer}>
                <section className={classes.FileTree}>
                    <FileTree files={data.files} setSelectedFile={setSelectedFile} />
                </section>
                <section className={classes.CodeMirror}>
                    <CodeMirror file={data.files[data.selectedFile]} setFileContent={setFileContent}/>
                </section>
                <section className={classes.Preview} hidden={previewHidden}>
                    <Preview projectId={projectId} setPreviewHidden={setPreviewHidden} />
                </section>
            </div>
            {/* </div> */}
        </div>
    );
}
