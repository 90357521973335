const apiPath = "https://us-central1-site-editor-2ed0a.cloudfunctions.net/api/v1/";

export async function saveFile(projectId, file) {
    if (projectId === 'None' || !projectId || !file) return;
    let body = {content: file.content};
    const response = await (
        await fetch(apiPath + `projects/${projectId}/files/${file.name}`, { 
            method: "PUT",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)})
    ).json()
    return response;
}

export async function stageProject(projectId) {
   await fetch(apiPath + `projects/${projectId}/stage`, { method: "GET"});
}

export async function publishProject(projectId) {
    await fetch(apiPath + `projects/${projectId}/publish`, { method: "GET"});
 }

export {apiPath};