import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Editor from '../editor/Editor'
import { apiPath } from '../../util/api';

const history = createBrowserHistory();

const useStyles = makeStyles({
  App: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
  },
  LandingCard: {
    margin: 'auto',
    padding: '12px'
  },
});

function Home({selectedProject, selectProjectFunc = f => f}) {
  const classes = useStyles();

  // Use some Effect Hooks
  const [data, setData] = React.useState({
    projects: [],
  });

  React.useEffect(() => {
    (async () => {
      const response = await (
        await fetch(apiPath + 'projects')
      ).json()
      let newProjects = {
        projects: response,
      };
      setData({...data, ...newProjects});
    })()
  }, [apiPath])

  // If we have selected a project, then go to the editor.
  if(selectedProject !== 'None'){
    return <Redirect to="/editor"/>
  }
  return (<div className={classes.App}>
    {data.projects.map(project => {
      return (
        <Card className={classes.LandingCard} key={project.name}>
          <CardContent>
            <Typography variant="h5">
              {project.name}
            </Typography>
            <Typography variant="body1">
              created: {project.createTime}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={() => {selectProjectFunc(project.name)}}>Open Editor</Button>
          </CardActions>
        </Card>
        )
    })}
  </div>)
}

export default function App() {
  // Set the selectedProject state
  const [selectedProject, setSelectedProject] = React.useState('None');

  let selectProject = function(projectId) {
    console.log('setting that project id: ' + projectId);
    // Set the state.
    setSelectedProject(projectId);
  };

  return (
    <Router history={history}>
      <Switch>
        <Route path="/editor" render={() => <Editor projectid={selectedProject} />} />
        <Route path="/" render={() => <Home selectProjectFunc={selectProject} selectedProject={selectedProject} />} />
      </Switch>
    </Router>
  );
}
